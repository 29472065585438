import React from "react";
import "./style.css";

export const Magic = (): JSX.Element => {
  return (
    <div className="magic">
      <div className="overlap-wrapper">
      <div className="background">
          <img src="img/landing-page-1.png" alt="Back Ground Image" />
      </div>

        <p className="tell-us-about-your">
          Tell us about your favorite creations <br />
          in real time and share, using our <br />
          Talking Head feature.
        </p>
        <p className="stream-creations-of">
          Stream creations of your own and the rest of the <br />
          community on our Movie Theatre streaming <br />
          platform
        </p>
        <div className="group">
          <div className="overlap-group">
            <div className="text-wrapper">Magic</div>
            <div className="text-wrapper-2">Magic</div>
          </div>
        </div>
        <div className="text-wrapper-3">Products</div>
        <img className="vector" alt="Vector" src="/img/vector.svg" />
        <div className="text-wrapper-4">Pricing</div>
        <div className="text-wrapper-5">Community</div>
        <div className="rectangle-2" />
        <div className="rectangle-3" />
        <div className="rectangle-4" />
        <div className="text-wrapper-6">Sign Up</div>
        <div className="text-wrapper-7">Log in</div>
        <h1 className="you-can-create">
          <span className="span">You can create</span>
          <span className="text-wrapper-8">
            {" "}
            <br />
          </span>
          <span className="text-wrapper-8">&nbsp;&nbsp;</span>
        </h1>
        <div className="text-wrapper-9">The</div>
        <div className="flex-container">
          <div className="text">
            <span className="text-wrapper-10">
              Upload your animated creations. Design Characters. <br />
            </span>
          </div>
          <div className="span-wrapper">
            <span className="text-wrapper-10">Create your own studio.&nbsp;&nbsp;Make magic.</span>
          </div>
        </div>
        <div className="rectangle-5" />
        <div className="rectangle-6" />
        <div className="rectangle-7" />
        <div className="rectangle-8" />
        <div className="text-wrapper-11">Start your studio</div>
        <div className="text-wrapper-12">Be a magician</div>
        <div className="text-wrapper-13">Be a talking head</div>
        <div className="text-wrapper-14">View the magic</div>
       
        <p className="at-capes-we-believe">
          At Capes we believe everyone can
          <br />
          create. Your studio is where you can <br />
          add original, curated, and modified <br />
          content
        </p>
        <p className="create-your-own">
          Create your own characters, <br />
          backgrounds, and creations <br />
          with our Capes Magic drawing <br />
          application
        </p>
        <div className="rectangle-11" />
        <img className="line" alt="Line" src="/img/line-2.svg" />
        <img className="top-guys" alt="Top guys" src="/img/top-guys-1.png" />
        <img className="line-2" alt="Line" src="/img/line-28.svg" />
        <img className="hand" alt="Hand" src="/img/hand-2-1.png" />
        <img className="sillies-zero" alt="Sillies zero" src="/img/sillies-zero-2-1.png" />
        <img className="line-3" alt="Line" src="/img/line-1.svg" />
        <div className="overlap-group-wrapper">
          <div className="overlap-2">
            <div className="overlap-3">
              <div className="overlap-4">
                <div className="rectangle-12" />
                <div className="ellipse" />
              </div>
              <img className="play-arrow" alt="Play arrow" src="/img/play-arrow.svg" />
              <img className="fast-forward" alt="Fast forward" src="/img/fast-forward.svg" />
              <img className="fast-rewind" alt="Fast rewind" src="/img/fast-rewind.svg" />
            </div>
            <img className="audio-2" alt="Audio" src="/img/audio-1.svg" />
          </div>
        </div>
        <div className="div-wrapper">
          <div className="overlap-5">
            <div className="overlap-6">
              <div className="overlap-7">
                <div className="rectangle-13" />
                <div className="ellipse-2" />
              </div>
              <img className="play-arrow-2" alt="Play arrow" src="/img/play-arrow-1.svg" />
              <img className="fast-forward-2" alt="Fast forward" src="/img/fast-forward-1.svg" />
              <img className="fast-rewind-2" alt="Fast rewind" src="/img/fast-rewind-1.svg" />
            </div>
            <img className="audio-3" alt="Audio" src="/img/audio-2.svg" />
          </div>
        </div>
        <img className="twitter" alt="Twitter" src="/img/twitter.svg" />
        <img className="instagram" alt="Instagram" src="/img/instagram.svg" />
        <img className="facebook" alt="Facebook" src="/img/facebook.svg" />
        <img className="vector-2" alt="Vector" src="/img/vector-1.svg" />
        <img className="us-dollar-circled" alt="Us dollar circled" src="/img/us-dollar-circled.svg" />
        <img className="organization" alt="Organization" src="/img/organization.svg" />
        <div className="text-wrapper-15">Sign up</div>
        <div className="group-2">
          <div className="overlap-8">
            <div className="overlap-group-2">
              <div className="ellipse-3" />
              <div className="ellipse-4" />
              <div className="ellipse-5" />
              <div className="ellipse-6" />
              <div className="ellipse-7" />
            </div>
            <img className="ellipse-8" alt="Ellipse" src="/img/ellipse-113.svg" />
          </div>
        </div>
        <div className="text-wrapper-16">Capes</div>
        <div className="rectangle-14" />
        <div className="mask-group" />
        <div className="community-support">
          <span className="text-wrapper-17">
            Community
            <br />
          </span>
          <span className="text-wrapper-18">
            <br />
            Support
            <br />
            Community
            <br />
            Safety Center
          </span>
        </div>
        <div className="legal-privacy-center">
          <span className="text-wrapper-17">
            Legal
            <br />
          </span>
          <span className="text-wrapper-18">
            <br />
            Privacy Center
            <br />
            Cookie Policy
            <br />
            Terms
            <br />
            Licenses
            <br />
            Moderation
          </span>
        </div>
        <div className="group-3">
          <div className="overlap-9">
            <div className="rectangle-15" />
            <div className="rectangle-16" />
            <img className="happy-cloud" alt="Happy cloud" src="/img/happy-cloud.svg" />
            <div className="rectangle-17" />
            <div className="rectangle-18" />
            <div className="rectangle-19" />
            <div className="ellipse-9" />
            <div className="ellipse-10" />
            <div className="ellipse-11" />
            <div className="group-4">
              <div className="overlap-group-3">
                <div className="overlap-group-4">
                  <div className="ellipse-12" />
                  <div className="ellipse-13" />
                  <div className="ellipse-14" />
                  <div className="ellipse-15" />
                  <div className="ellipse-16" />
                </div>
                <img className="ellipse-17" alt="Ellipse" src="/img/ellipse-113-1.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className="group-5">
          <div className="overlap-10">
            <div className="rectangle-23" />
            <img className="rectangle-24" alt="Rectangle" src="/img/rectangle-9.svg" />
            <img className="sillies" alt="Sillies" src="/img/sillies-2-1.png" />
            <div className="rectangle-25" />
            <div className="ellipse-18" />
            <div className="ellipse-19" />
            <div className="ellipse-20" />
            <div className="ellipse-21" />
            <div className="group-6">
              <div className="overlap-group-5">
                <div className="rectangle-26" />
                <div className="rectangle-27" />
                <img className="ellipse-22" alt="Ellipse" src="/img/ellipse-101.svg" />
                <img className="ellipse-23" alt="Ellipse" src="/img/ellipse-110.svg" />
                <img className="ellipse-24" alt="Ellipse" src="/img/ellipse-109.svg" />
                <div className="rectangle-28" />
                <div className="rectangle-29" />
                <div className="rectangle-30" />
                <div className="ellipse-25" />
                <div className="ellipse-26" />
                <div className="ellipse-27" />
                <div className="ellipse-28" />
                <div className="text-wrapper-19">100 %</div>
              </div>
            </div>
            <div className="group-7">
              <div className="overlap-11">
                <div className="overlap-group-4">
                  <div className="ellipse-12" />
                  <div className="ellipse-13" />
                  <div className="ellipse-14" />
                  <div className="ellipse-15" />
                  <div className="ellipse-16" />
                </div>
                <img className="ellipse-17" alt="Ellipse" src="/img/ellipse-113-2.svg" />
              </div>
            </div>
            <img className="hand-2" alt="Hand" src="/img/hand-1.png" />
          </div>
        </div>
        <div className="product-download">
          <span className="text-wrapper-17">
            Product
            <br />
          </span>
          <span className="text-wrapper-18">
            <br />
            Download
            <br />
            Nitro
            <br />
            Status
          </span>
        </div>
        <div className="rectangle-31" />
        <img className="movi-couple" alt="Movi couple" src="/img/movi-couple-1.png" />
      </div>

    </div>
  );
};
