import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyAhY76dl5B_vQKyizPS912x6-BuiIgzL3U",
    authDomain: "capes-9e6a9.firebaseapp.com",
    databaseURL: "https://capes-9e6a9.firebaseio.com",
    projectId: "capes-9e6a9",
    storageBucket: "capes-9e6a9.appspot.com",
    messagingSenderId: "795061489789",
    appId: "1:795061489789:web:c8b0c945ddde3854792114",
    measurementId: "G-W23ZKLGH0Z"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
