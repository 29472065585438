import React from "react";
import { StrictMode } from "react";
import ReactDOMClient from "react-dom/client";
import { AuthProvider } from "./provider/AuthProvider";
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';


const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
    <StrictMode>
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline>
                    <App />
                </CssBaseline>
            </ThemeProvider>
        </AuthProvider>
    </StrictMode>
);
